body {
  color: #000;
  background: none;
}

#layer-1 {
  display: none;
}

#logo {
  filter: grayscale() brightness(.1);
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.light-spot {
  filter: grayscale();
}

/*# sourceMappingURL=index.2220001f.css.map */
