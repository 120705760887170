body
  background: transparent
  color: #000

#layer-1
  display: none

#logo
  -webkit-print-color-adjust:exact !important
  print-color-adjust: exact !important
  filter: grayscale(100%) brightness(0.1)

.light-spot
  filter: grayscale(100%)